<template>
  <gf-table :data="contacts" :selection-command="selectionCommand" v-loading="loading" @command="command" @row-click="readContact">
    <el-dialog title="Modify Contact" :visible.sync="contactModalVisible" append-to-body v-loading="contactModalLoading" :close-on-click-modal="false">
      <gf-form>
        <el-form size="small" label-width="120px" :model="contact" ref="contact" :rules="contactRules">
          <el-form-item label="First Name" prop="fname">
            <el-input v-model="contact.fname"></el-input>
          </el-form-item>
          <el-form-item label="Last Name" prop="lname">
            <el-input v-model="contact.lname"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="contact.email"></el-input>
          </el-form-item>
          <el-form-item label="Phone Number" prop="phone_number">
            <el-input v-model="contact.phone_number"></el-input>
          </el-form-item>
          <el-form-item label="Fax" prop="fax_number">
            <el-input v-model="contact.fax_number"></el-input>
          </el-form-item>
          <el-form-item label="Mobile" prop="mobile_number">
            <el-input v-model="contact.mobile_number"></el-input>
          </el-form-item>
          <el-form-item label="Title" prop="title">
            <el-input v-model="contact.title"></el-input>
          </el-form-item>
          <el-form-item label="Department" prop="department">
            <el-input v-model="contact.department"></el-input>
          </el-form-item>
          <el-form-item label="Notes" prop="notes">
            <el-input type="textarea" v-model="contact.notes"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <gf-button type="secondary" @click="contactModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="danger" @click="removeContact">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Delete
          </gf-button>
          <gf-button type="primary" @click="saveContact">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z" fill="#000000" fill-rule="nonzero"/>
                    <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="5" rx="0.5"/>
                  </g>
                </svg>
              </span>
            </template>
            Save
          </gf-button>
        </template>
      </gf-form>
    </el-dialog>
    <el-dialog title="Delete Company Contacts" :visible.sync="deleteModalVisible" append-to-body v-loading="deleteModalLoading" :close-on-click-modal="false">
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-danger svg-icon-xxl">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"/>
                <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3"/>
                <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1"/>
                <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1"/>
              </g>
            </svg>
          </span>
        </template>
        You are trying to delete {{ this.selected.length }} contacts. This action cannot be undone. Are you sure?
      </gf-alert>
      <template #footer>
        <gf-button type="secondary" class="mr-2" @click="deleteModalVisible = false">
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                    <rect x="0" y="7" width="16" height="2" rx="1"/>
                    <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="danger" @click="remove">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                  <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          Delete
        </gf-button>
      </template>
    </el-dialog>
    <el-table-column type="selection"></el-table-column>
    <el-table-column label="Name" min-width="180">
      <template #default="slot">
        {{ slot.row.fname }} {{ slot.row.lname }}
      </template>
    </el-table-column>
    <el-table-column label="Phone" min-width="150" prop="phone_number">
    </el-table-column>
    <el-table-column label="Fax" min-width="150" prop="fax_number">
    </el-table-column>
    <el-table-column label="Email" min-width="200" prop="email">
    </el-table-column>
    <el-table-column label="Title" min-width="150" prop="title">
    </el-table-column>
    <el-table-column label="Department" min-width="150" prop="department">
    </el-table-column>
  </gf-table>
</template>

<script>
// services
import CompanyContactService from '@/services/v1/CompanyContact'

export default {
  props: {
    reload: Boolean,
    pagination: Object
  },
  data () {
    return {
      // main
      contacts: [],
      contact: {
        id: null,
        fname: null,
        lname: null,
        email: null,
        phone_number: null,
        fax_number: null,
        mobile_number: null,
        title: null,
        department: null,
        notes: null
      },
      selectionCommand: [
        { command: 'delete', label: 'Delete' }
      ],
      selected: [],

      // modal
      contactModalVisible: false,
      contactModalLoading: false,
      deleteModalVisible: false,
      deleteModalLoading: false,

      //
      loading: false,

      // rules
      contactRules: {
        fname: [
          { required: 'true', message: 'First Name can\'t be blank', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    reload () {
      if (this.reload) this.getContacts()
      this.$emit('update:reload', false)
    }
  },
  methods: {
    // main
    command (event) {
      this.selected = event.selection
      if (event.cmd === 'delete') {
        this.deleteModalVisible = true
      }
    },
    readContact (row) {
      this.contactModalVisible = true
      this.contact = this.$_.cloneDeep(row)
    },
    async saveContact () {
      try {
        this.contactModalLoading = true
        const contact = this.$_.cloneDeep(this.contact)

        this.$Sanitize(contact)
        delete contact.id

        const ccService = new CompanyContactService(this.$route.params.id, this.contact.id)
        await ccService.update(contact)
        this.$message.success('Contact successfully modified')

        this.contactModalVisible = false
        this.$ClearField('contact')
        this.getContacts()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.contactModalLoading = false
      }
    },
    removeContact () {
      this.$confirm('You are trying to delete this contact. This action cannot be undone. Are you sure?', 'Remove Contact', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        try {
          this.contactModalLoading = true
          const ccService = new CompanyContactService(this.$route.params.id, this.contact.id)
          await ccService.del()
          this.$message.success('Contact was successfully deleted')

          this.getContacts()
          this.contactModalVisible = false
        } catch (error) {
          this.$Error(error)
        } finally {
          this.contactModalLoading = true
        }
      }).catch(() => {})
    },
    async remove () {
      try {
        this.deleteModalLoading = true
        const ccService = new CompanyContactService(this.$route.params.id)
        await ccService.delMultiple(this.selected.map(s => s.id))
        this.$message.success(`${this.selected.length} Contacts was successfully deleted`)

        this.getContacts()
        this.deleteModalVisible = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.deleteModalLoading = false
      }
    },

    async getContacts () {
      try {
        this.loading = true
        const ccService = new CompanyContactService(this.$route.params.id)
        const result = await ccService.list(this.pagination.page)
        this.contacts = result.data.rows

        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$emit('update:active', 1)
    this.getContacts()
  }
}
</script>
